import { MsalAuthProvider, LoginType } from 'react-aad-msal';
const clientId = process.env.REACT_APP_CLIENT_ID;

// Msal Configurations
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/b93bd2a6-d7e8-48ab-a86f-114270731168',
    clientId: clientId,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

// Authentication Parameters
const authenticationParameters = {
  scopes: ['openid'],
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + '/auth.html',
};

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);
