import React, { Component, Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { CSpinner } from '@coreui/react-pro';
import './scss/style.scss';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider } from './authProvider';

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            <Route
              path="*"
              name="Home"
              element={
                <AzureAD provider={authProvider} forceLogin={true}>
                  {({ login, logout, authenticationState }) => {
                    const isInProgress = authenticationState === AuthenticationState.InProgress;
                    const isAuthenticated =
                      authenticationState === AuthenticationState.Authenticated;
                    const isUnauthenticated =
                      authenticationState === AuthenticationState.Unauthenticated;

                    if (isAuthenticated) {
                      return <DefaultLayout />;
                    } else if (isUnauthenticated || isInProgress) {
                      return <div>Loading...</div>;
                    }
                  }}
                </AzureAD>
              }
            />
          </Routes>
        </Suspense>
      </HashRouter>
    );
  }
}

export default App;
